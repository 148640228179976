import { useWindowSize } from '@vueuse/core';
import { computed } from 'vue';

import {
  isDesktopView,
  isDesktopXLView,
  isMobileView,
  isTabletView
} from '@/shared/utils/dom';

export const useViewport = () => {
  const { width } = useWindowSize();

  const isMobile = computed(() => isMobileView(width.value));
  const isTablet = computed(() => isTabletView(width.value));
  const isDesktop = computed(() => isDesktopView(width.value));
  const isDesktopXL = computed(() => isDesktopXLView(width.value));

  return {
    isMobile,
    isTablet,
    isDesktop,
    isDesktopXL
  };
};
